import Footer from "./Footer";
import Script from "next/script";
import ModalRe from "./ModalRe";

const Layout = ({ children, data }) => {
  const { footer } = data;

  return (
    <>
      {/* GTM Head */}
      <Script
        id="gtm-head"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
        var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
        j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-NFMSJS4J');`,
        }}
      />

      <Script type="text/javascript" src="/static/bootstrap.min.js" />
      <Script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.gaKey}`}
      />
      <Script
        type="text/javascript"
        src="https://blockify.synctrack.io/api/file/blockify-embed?instanceId=7ee4b267-b97f-43c8-bb82-a4bd46c6fa01"
        async
      ></Script>
      <Script
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${process.env.gaKey}', {
              page_path: window.location.pathname,
            });
          `,
        }}
      />
      <ModalRe />
      {children}
      <Footer data={footer} />
    </>
  );
};

export default Layout;
